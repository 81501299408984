import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards';

const appRoutes: Routes = [
    { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
    { path: 'users/activation/:id', loadChildren: () => import('./account-activation/account.activation.module').then(m => m.AccountActivationModule) },   
    { path: 'change-password', loadChildren: () => import('./change-password/change.password.module').then(m => m.ChangePasswordModule)},
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);