import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app',
    styleUrls: ['./app.component.css'],  
    templateUrl: 'app.component.html'
})

export class AppComponent { 
    constructor(private translate: TranslateService) {
        translate.setDefaultLang('en');
    }
    isLoggedIn() {        
        return (localStorage.getItem('currentUser') != null);        
    }
}