import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { NotifierOptions } from "angular-notifier";

export class Constants {
     
    public static readonly POPUP_MODEL_CONFIG : NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
            backdropClass: 'custom-backdrop',
      windowClass: 'custom-modal-window'
    };

    public static readonly GLOBLE_NOTIFIER_OPTION: NotifierOptions = {
        position: {
          horizontal: {
            position: 'middle',
            distance: 7
          },
          vertical: {
            position: 'top',
            distance: 110,
            gap: 10
          }
        },
        theme: 'material',
        behaviour: {
          autoHide: 5000,
          onClick: 'hide',
          onMouseover: 'pauseAutoHide',
          showDismissButton: true,
          stacking: 4
        },
        animations: {
          enabled: true,
          show: {
            preset: 'slide',
            speed: 300,
            easing: 'ease'
          },
          hide: {
            preset: 'fade',
            speed: 300,
            easing: 'ease',
            offset: 50
          },
          shift: {
            speed: 300,
            easing: 'ease'
          },
          overlap: 150
        }
    };
}