export const environment = {
  production: false,
  envName: 'test',
  appClientId: "portal",
  apiUrl: 'https://testbusinessportal.sinric.pro',
  sseUrl: 'https://portal.sinric.pro/sse',
  googleCaptchaSiteKey: "6Lfm5qkUAAAAADI80yBrLJxY3tgeoOoJSZuCFbRF",
  version: '1.0.2-test'
}; 

// ng build --configuration=test
// npm run build:test

/*
Paypal test login
Email ID:
sb-nfefn384118@personal.example.com

Password: 
SAT5a%yn


// Paypal Sandbox (Thai)
// sb-r47qoc23835767@personal.example.com
// Lfx3QV./

*/