import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner'; 
import { ClipboardModule } from 'ngx-clipboard';
import { AppComponent }  from './app.component';
import { routing }        from './app.routing'; 
import { AuthGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertService, AuthenticationService, APIService, SSEService, LayoutService, StateService, GoogleAnalyticsService} from './_services';
import { AvatarModule } from 'ngx-avatar';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CommonModule } from '@angular/common';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module'
import { AngularNotifierModule } from './shared/modules/angular-notifier/angular-notifier.module'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './_shared/shared.module';
import { SharedDialog } from './_shared/shared.dialog';

@NgModule({
    imports: [
        BrowserModule,
        SharedModule,
        SharedDialog,
        FormsModule,
        ReactiveFormsModule,
        NgxCaptchaModule,
        HttpClientModule,
        CommonModule,
        LanguageTranslationModule,
        NgxSpinnerModule, 
        ClipboardModule,
        AvatarModule,
        AngularNotifierModule, 
        BrowserAnimationsModule,
        routing,         
    ],
    declarations: [
        AppComponent,         
    ],
    providers: [
        GoogleAnalyticsService,
        AuthGuard,
        AlertService,
        AuthenticationService,
        APIService,
        SSEService,
        LayoutService,
        StateService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
 
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(protected _googleAnalyticsService: GoogleAnalyticsService) { }
 }