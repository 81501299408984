import { NgModule } from '@angular/core';
import { FilterPipe } from '../_pipes/filter-pipe';
import { FileSizePipe } from 'src/app/_pipes/file.size.pipe';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from 'src/app/_pipes/date-ago.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { FileSelectDirective, FileDropDirective, FileUploadModule } from 'ng2-file-upload';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FileUploadModule
  ],
  declarations: [
    FilterPipe, FileSizePipe, DateAgoPipe
  ],
  exports: [
    FilterPipe, FileSizePipe, DateAgoPipe, FileSelectDirective, FileDropDirective, FormsModule,
    FileUploadModule
  ]
})

export class SharedModule { }