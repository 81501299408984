import { CommonModule } from '@angular/common';
import { NgModule  } from '@angular/core'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ResetPasswordDialogComponent } from '../layout/dialogs/reset-password/reset-password-dialog';
import { YesNoDialogComponent } from '../layout/dialogs/yes-no/yes-no-dialog';
import { DeviceDeactivateDialogComponent } from '../layout/dialogs/device-deactivate-dialog/device-deactivate-dialog';


@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    YesNoDialogComponent,
    ResetPasswordDialogComponent,
    DeviceDeactivateDialogComponent
  ],
  exports: [
    YesNoDialogComponent,
    ResetPasswordDialogComponent,
    DeviceDeactivateDialogComponent
  ]
})

export class SharedDialog { }