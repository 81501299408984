import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GetDashboard, UserSession } from '../_models';
 import { GetDevicesResp } from "../_models/get.devices.response"
import { getProductsResp } from '../_models/get.products.response';
import { BaseResponse } from '../_models/base.response';
import { getMySelfResp } from '../_models/get.myself.response';
import { getSubscriptionResp } from '../_models/get.subscription.response';
import { GetUsersResp } from '../_models/get.users.response';
import { GetOtaUpdatesResponse } from '../_models/get.otaupdates.response';
import { GetActivityLogResp } from '../_models/get.activitylog.response';
import { getRetailItemsResp } from '../_models/get.retail.items.response';
import { GetAccountResponse } from '../_models/get.account.response';
import { GetModulesResponse } from '../_models/get.modules.response';
import { GetOtaAffectedModuleCount } from '../_models/get.ota.affected.module.count';
 

@Injectable()
export class APIService {
    constructor(private http: HttpClient) { }
         
    register(businessName: string, name: string, businessEmail: string, password: string, timeZone : string, 
        language: string, recaptcha: string, clientId: string, typeOfBusiness: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/users/register`;
        const postData = { businessName, name, businessEmail, password, timeZone, language, recaptcha, clientId, typeOfBusiness };
        return this.http.post<GetAccountResponse>(endpointUrl, postData);
    }

    getDashboard() {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/dashboard`;
        return this.http.get<GetDashboard>(endpointUrl);
    }
    
    getDevices(page: number, limit: number, sort: any) {
        const queryParameters = {
            "page": `${page}`,
            "limit": `${limit}`,
            "sort" : sort
        };
         
        const headers = new HttpHeaders({});
        return this.http.get<GetDevicesResp>(`${environment.apiUrl}/api/v1/business/devices`, { params: queryParameters, headers: headers } );
    }

    searchDevices(search: any, page: number, limit: number, sort: any) {
        const queryParameters = {
            "search": `${search}`,
            "limit" : limit
        };
 
        const headers = new HttpHeaders({});
        return this.http.get<GetDevicesResp>(`${environment.apiUrl}/api/v1/business/devices/find`, { params: queryParameters, headers: headers } );
    }

    deactivateOrActivate(deviceId: any, deactivated: any, deactivatedReason: any) {
        const postData = { deactivated: deactivated, deactivatedReason: deactivatedReason };
        const endpointUrl = `${environment.apiUrl}/api/v1/business/devices/${deviceId}/deactivate`;
        return this.http.post<BaseResponse>(endpointUrl, postData);
    }

    getDeviceActivityLog(deviceId: any, sort: any) {
        const headers = new HttpHeaders({});
        const queryParameters = {
            "deviceId": `${deviceId}`,
            "sort": sort,
            "limit" : "200"
        };

        return this.http.get<GetActivityLogResp>(`${environment.apiUrl}/api/v1/business/devices/activitylog`, { params: queryParameters, headers: headers } );
    }

    getUserActivityLog(userId: any) {
        const headers = new HttpHeaders({});
        const queryParameters = {
            "userId": `${userId}`,
        };
        return this.http.get<GetActivityLogResp>(`${environment.apiUrl}/api/v1/business/users/activitylog`, { params: queryParameters, headers: headers } );
    }
 
    getUsers(page: number, limit: number, sort: any) {
        const queryParameters = {
            "page": `${page}`,
            "limit": `${limit}`,
            "sort" : sort
        };

        const headers = new HttpHeaders({});
        return this.http.get<GetUsersResp>(`${environment.apiUrl}/api/v1/business/users`, { params: queryParameters, headers: headers } );
    }

    deleteUser(id: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/users/${id}`;
        return this.http.delete<BaseResponse>(endpointUrl);
    }
      
    findUsers(search: any) {
        const queryParameters = {
            "search": `${search}`
        };

        const headers = new HttpHeaders({});
         return this.http.get<GetUsersResp>(`${environment.apiUrl}/api/v1/business/users/find`, { params: queryParameters, headers: headers } );
    }

    resetPassword(userId, password, mustChangePassword) {
        const postData = {userId: userId, password: password, mustChangePassword: mustChangePassword };
        const endpointUrl = `${environment.apiUrl}/api/v1/business/users/resetpassword`;
        return this.http.post<BaseResponse>(endpointUrl, postData);
    }

    getSubscriptions() {
        const queryParameters = {};
        const headers = new HttpHeaders({});
        return this.http.get<getSubscriptionResp>(`${environment.apiUrl}/api/v1/business/subscriptions`, { params: queryParameters, headers: headers } );
    }
    
    logout() {
        const endpointUrl = `${environment.apiUrl}/api/v1/auth/logout`;
        return this.http.post<BaseResponse>(endpointUrl, {});
    }
   
    updateMySelf(id: string, businessName: string, name: string, businessEmail: string, timeZone : string, 
        language: string, typeOfBusiness: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/users/myself`;
        const postData = { id, businessName, name, businessEmail, timeZone, language, typeOfBusiness };    
        return this.http.patch<getMySelfResp>(endpointUrl, postData);
    }

    getMySelf() {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/users/myself`;
        return this.http.get<getMySelfResp>(endpointUrl);
    }
    
    deleteMySelf(reason) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/users/myself`;
        const httpParams = new HttpParams().set('reason', reason);
        const options = { params: httpParams };
        return this.http.delete<getMySelfResp>(endpointUrl, options);
    }

    changePassword(oldPassword: any, newPassword: any) {
        const postData = {oldPassword: oldPassword,  newPassword: newPassword};
        const endpointUrl = `${environment.apiUrl}/api/v1/business/users/myself/change-password`;
        return this.http.put<BaseResponse>(endpointUrl, postData);
    }
 
    getOtaUpdates() {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/otaupdates`;
        return this.http.get<GetOtaUpdatesResponse>(endpointUrl);
    }

    deleteOtaUpdate(id) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/otaupdates/${id}`;
        return this.http.delete<BaseResponse>(endpointUrl);
    }
    
    getProducts(includeBuiltInProducts = true) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/products`;
        const queryParameters = { "includeBuiltInProducts": includeBuiltInProducts };
        return this.http.get<getProductsResp>(endpointUrl, { params: queryParameters } );
    }

    getRetailItems() {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/retailitems`;
        return this.http.get<getRetailItemsResp>(endpointUrl);
    }

    createRetailItem(name, model, active, description, deviceTypes, manufacturerId, provisioningImageUrl, chipset, provisioningImageFit = 'fitWidth', preconfigured = false) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/retailitems`;
        const postData = { name, model, active, description, deviceTypes, manufacturerId, provisioningImageUrl, chipset, provisioningImageFit, preconfigured};
        return this.http.post<BaseResponse>(endpointUrl, postData);
    }

    uploadRetailItemImage(file: File): Promise<any> {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/retailitems/image-upload`;
        const formData: FormData = new FormData();
        formData.append('file', file);
    
        return new Promise((resolve, reject) => {
            this.http.post(endpointUrl, formData, {
                responseType: 'json',
                observe: 'response'
            }).subscribe({
                next: (response: HttpResponse<any>) => {
                    if (response.body) {
                        resolve({success: true, ...response.body});
                    } else {
                        resolve({success: false });
                    }
                },
                error: (error: any) => {
                    console.error('Upload error:', error);
                    resolve({success: false });
                }
            });
        });
    }

    updateRetailItem(id, name, model, active, description, deviceTypes, manufacturerId, provisioningImageUrl, chipset, provisioningImageFit = 'fitWidth', preconfigured = false) {
        const postData = { id, name, model, active, description, deviceTypes, manufacturerId, provisioningImageUrl, chipset, provisioningImageFit,  preconfigured};
        const endpointUrl = `${environment.apiUrl}/api/v1/business/retailitems`;
        return this.http.put<BaseResponse>(endpointUrl, postData);
    }

    deleteRetailItem(id) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/retailitems/${id}`;
        return this.http.delete<BaseResponse>(endpointUrl);
    }

    downloadRetailItemCode(id, ide) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/retailitems/${id}/code`;
        const queryParameters = {
            "ide": `${ide}`
        };       
        const headers = new HttpHeaders({});
        return this.http.get(endpointUrl, { params: queryParameters, headers: headers, responseType: "blob" } );
    }

    activateAccount(verificationToken: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/users/activation/${verificationToken}`;
        return this.http.get<BaseResponse>(endpointUrl);
    }
 
    forceOtaUpdate(otaUpdateId: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/otaupdates/${otaUpdateId}/force`;
        const postData = { };
        return this.http.post<BaseResponse>(endpointUrl, postData);
    }

    otaAffectedModuleCount(macAddresses: string, retailItemId: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/otaupdates/ota-affected-module-count`;
        const postData = { macAddresses, retailItemId };
        return this.http.post<GetOtaAffectedModuleCount>(endpointUrl, postData);
    }

    getModules() {
        const endpointUrl = `${environment.apiUrl}/api/v1/modules`;
        return this.http.get<GetModulesResponse>(endpointUrl);
    }

}