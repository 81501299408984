import { NgModule } from '@angular/core';
import { AngularNotifierComponent } from './angular-notifier.component';
import {NotifierModule, NotifierOptions} from 'angular-notifier';
import {FormsModule} from '@angular/forms';
import { Constants } from 'src/app/_util/constants';

@NgModule({
  declarations: [
    AngularNotifierComponent
  ],
  imports: [
    FormsModule,
    NotifierModule.withConfig(Constants.GLOBLE_NOTIFIER_OPTION),
  ],
  exports: [
    AngularNotifierComponent
  ],
})
export class AngularNotifierModule { }