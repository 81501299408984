import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient, private translate: TranslateService) { }

    login(username: string, password: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/business/auth`;
        const authorizationData = 'Basic ' + btoa(username + ':' + password);
        
        const headerOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': authorizationData
            })
        };

        return this.http.post<any>(endpointUrl, { client_id: environment.appClientId },  headerOptions)
            .pipe(map(response => {
                if (response.success) {                    
                    const language = response.account.language || 'en';
                    this.translate.use(language);
                    if(!environment.production) console.log('language: ', language);
                    localStorage.setItem('currentUser', JSON.stringify(response));                    
                }
                return response;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}